<template lang="pug">
  div
    base-snackbar(
      :errors.sync="orderTransactionPutErrors"
    )
    base-snackbar(
      :errors.sync="orderTransactionGetErrors"
    )
    v-row
      v-col(cols="3")
        v-toolbar(
          color="primary"
          dark
          flat
          dense
        )
          span Add Transaction
        v-row
          v-col(cols="12")
            v-text-field(
              label="Date"
              type="date"
              v-model="date"
              :error-messages="orderTransactionPostErrors.date || []"
            )
          v-col(cols="12")
            v-text-field(
              label="Amount"
              type="number"
              v-model.number="amount"
              :error-messages="orderTransactionPostErrors.amount || []"
            )
          v-col(cols="12")
            v-btn(
              color="green"
              dark
              :loading="orderTransactionPosting"
              @click="storeTransaction"
            )
              span Add Transaction
      v-col(cols="9")
        v-toolbar(
          color="primary"
          dark
          flat
          dense
        )

          span History
          v-spacer
          v-progress-circular(
            v-if="orderTransactionGetting"
            indeterminate
            color="white"
          )
        v-simple-table(dense)
          template(v-slot:default)
            thead
              tr
                th Date
                th.text-right Amount
            tbody
              tr(v-for="item in orderTransactionList" :key="item.id")
                td {{ item.date ? item.date.dateFormat() : null }}
                td.text-right {{ (item.amount || 0).phpFormat() }}
              tr
                td(colspan="2") &nbsp;
              tr(v-if="orderData.vat")
                th 
                  | Total Sales
                  | &nbsp; 
                  small (VAT Inclusive)
                td.text-right {{ totalSales.phpFormat() }}
              tr(v-if="orderData.vat")
                th Less (12% VAT)
                td.text-right {{ vatAmount.phpFormat() }}
              tr(v-if="orderData.vat")
                th NET of VAT/TOTAL
                td.text-right {{ itemsTotal.phpFormat() }}
              tr(v-if="orderData.vat")
                th Less Withholding ({{ (withholdingPercentage * 100) }}%)
                td.text-right {{ withholding.phpFormat() }}
              tr(v-if="orderData.vat")
                th Total Amount Due
                td.text-right {{ totalAmountDue.phpFormat() }}
              tr
                th Paid
                td.text-right {{ totalAmount.phpFormat() }}
              tr
                th Balance
                td.text-right {{ balance.phpFormat() }}
</template>
<style lang="sass" scope>
  .transaction-confirm-table
    width: 100%
    border-collapse: collapse
    & td, & th
      border: 1px solid #CFD8DC
      color: black
    & td
      text-align: right
</style>
<script>
import orderTransactionRepository from '@/repositories/order-transaction.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [orderTransactionGetVars, orderTransactionGetVarNames] = requestVars({ identifier: 'order-transaction' })
const [orderTransactionPostVars, orderTransactionPostVarNames] = requestVars({ identifier: 'order-transaction', hasData: false, request: 'post' })

const orderTransactionVarGetHandler = new VueRequestHandler(null, orderTransactionGetVarNames)
const orderTransactionVarPostHandler = new VueRequestHandler(null, orderTransactionPostVarNames)

function createTransactionConfirmTable(columns) {
  const table = document.createElement('table')
  table.className = 'transaction-confirm-table'
  columns.forEach(item => {
    const tr = document.createElement('tr')
    const th = document.createElement('th')
    const td = document.createElement('td')
    const small = document.createElement('small')
    small.innerText = item.text
    th.appendChild(small)
    td.innerText = item.value
    tr.appendChild(th)
    tr.appendChild(td)
    table.appendChild(tr)
  })
  return table.outerHTML
}

const inputVars = () => ({
  date: Window.getCurrentDate(),
  amount: null,
})

export default {
  name: 'Transactions',
  props: ['orderData', 'popup', 'orderDetails'],
  created() {
    this.initWebsockets()
  },
  data() {
    return {
      ...orderTransactionGetVars,
      ...orderTransactionPostVars,
      ...inputVars(),
    }
  },
  computed: {
    orderId() {
      return this.orderData.id
    },
    balance() {
      const balance = this.totalAmountDue - this.totalAmount
      return balance < 0 ? 0 : balance
    },
    totalSales() {
      return this.itemsTotal + this.vatAmount
    },
    itemsTotal() {
      return this.orderDetails.reduce((total, item) => total + ((item.unit_price - item.discount) * item.quantity), 0)
    },
    subTotal() {
      return this.itemsTotal * 0.98
    },
    totalAmountDue() {
      return this.totalSales - this.withholding
    },
    vatAmount() {
      return this.itemsTotal * 0.12 * this.orderData.vat
    },
    withholding() {
      if (this.orderData.orderType === 'services') {
        return this.itemsTotal * 0.02 * this.orderData.vat
      }
      if (this.orderData.orderType === 'materials') {
        return this.itemsTotal * 0.01 * this.orderData.vat
      }
      return 0
    },
    withholdingPercentage() {
      if (this.orderData?.orderType === 'services') {
        return 0.02
      }
      if (this.orderData?.orderType === 'materials') {
        return 0.01
      }
      return 0
    },
    customerId() {
      return this.orderData.customerId
    },
    totalAmount() {
      return this.orderTransactionList.reduce((total, item) => total + item.amount, 0)
    },
  },
  watch: {
    popup(val) {
      console.log(this.orderData)
      if (!val) return
      this.getTransactions()
    },
  },
  methods: {
    initWebsockets() {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
    },
    dbStoreEvent({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'OrderTransaction' && data.order_id === this.orderId) {
        this.orderTransactionList.push(data)
      }
    },
    getTransactions() {
      const handler = orderTransactionVarGetHandler
      const repository = orderTransactionRepository.getByOrder
      handler.setVue(this)
      handler.execute(repository, [this.orderId])
    },
    storeTransaction() {
      const remainingBalance = this.balance - this.amount
      const tableData = [
        { text: 'Amount', value: this.amount.phpFormat() },
        { text: 'Balance', value: this.balance.phpFormat() },
        { text: 'Remaining', value: (remainingBalance < 0 ? 0 : remainingBalance).phpFormat() },
      ]
      this.$confirm(createTransactionConfirmTable(tableData), { title: 'Add Transaction', color: 'purple' })
        .then(confirm => {
          if (!confirm) return
          const handler = orderTransactionVarPostHandler
          const repository = orderTransactionRepository.store
          const data = this.getInputData()
          handler.setVue(this)
          handler.execute(repository, [data])
        })
    },
    getInputData() {
      const defaults = inputVars()
      return Object.keys(defaults)
        .concat(['orderId', 'customerId'])
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
    },
  },
}
</script>